import React, { Component } from 'react';
import Panorama from "./../panorama/Panorama";
import LoadingIcon from "./img/home.png";
import './BookReader.css';

class BookReader extends Component {
    state = {
        currentPage: 0,
        availablePages: 0,
        bookData: {
            title: "",
            pageText: [],
            pageImage: [],
            pageScroll: []
        },

        // Swipe functionality
        left: 0,
        originalOffset: 0,
        velocity: 0,
        timeOfLastDragEvent: 0,
        touchStartX: 0,
        prevTouchX: 0,
        beingTouched: false,
        height: 0,
        deltaX: 0
    }

    async componentDidMount() {
        console.log("Loading book: " + this.props.activeBook);
        const { activeBook } = this.props;
        let activeBookConfig = {};
        for (let i = 0; i < this.props.bookConfig.books.length; i++) {
            const currentBook = this.props.bookConfig.books[i];
            if (currentBook.systemName === activeBook) {
                activeBookConfig = currentBook
                break;
            }
        }
        let bookData = {
            title: activeBookConfig.title,
            pageText: [],
            pageImage: [],
            pageScroll: []
        }
        for (let i = 1; i <= activeBookConfig.pages; i++) {
            // Transfer page text to state
            bookData.pageText.push(activeBookConfig.text["page-" + i]);

            // Transfer page scroll settings 
            bookData.pageScroll.push(activeBookConfig.scrollSettings[i-1]);

            // Import all page graphics
            await import(`./../../books/${activeBook}/Page${i}.svg`).then(image => {
                bookData.pageImage.push(image.default);
            });
        }
        this.setState({
            bookData,
            availablePages: activeBookConfig.pages
        });
    }

    backToHome = () => {
        this.props.toggleReadingBook(null);
    }

    // Swipe functionality
    handleStart(clientX) {
        this.setState({
            originalOffset: this.state.left,
            velocity: 0,
            timeOfLastDragEvent: Date.now(),
            touchStartX: clientX,
            beingTouched: true,
        });
    }
    handleMove(clientX) {
        if (this.state.beingTouched) {
            const touchX = clientX;
            const currTime = Date.now();
            const elapsed = currTime - this.state.timeOfLastDragEvent;
            const velocity = 20 * (touchX - this.state.prevTouchX) / elapsed;
            const deltaX = touchX - this.state.touchStartX + this.state.originalOffset;
            this.setState({
                left: 0,
                velocity,
                timeOfLastDragEvent: currTime,
                prevTouchX: touchX,
                deltaX: (deltaX < -150 || deltaX > 150) ? deltaX : 0
            });
        }
    }
    handleEnd() {
        if (this.state.deltaX < -150) {
            // Swiped left - Go to next page
            const availablePages = this.state.availablePages;
            const currentPage = this.state.currentPage;
            if (currentPage + 1 < availablePages) {
                this.setState({
                    currentPage: currentPage + 1
                });
            } else {
                this.backToHome();
            }
        } else if (this.state.deltaX > 150) {
            // Swiped right - Go to previous page
            const currentPage = this.state.currentPage;
            if (currentPage - 1 >= 0) {
                this.setState({
                    currentPage: currentPage - 1
                });
            }
        }
        this.setState({
            velocity: this.state.velocity,
            touchStartX: 0,
            beingTouched: false,
            deltaX: 0
        });
    }
    handleTouchStart(touchStartEvent) {
        touchStartEvent.preventDefault();
        this.handleStart(touchStartEvent.targetTouches[0].clientX);
    }
    handleTouchMove(touchMoveEvent) {
        this.handleMove(touchMoveEvent.targetTouches[0].clientX);
    }
    handleTouchEnd() {
        this.handleEnd();
    }
    handleMouseDown(mouseDownEvent) {
        mouseDownEvent.preventDefault();
        this.handleStart(mouseDownEvent.clientX);
    }
    handleMouseMove(mouseMoveEvent) {
        this.handleMove(mouseMoveEvent.clientX);
    }
    handleMouseUp() {
        this.handleEnd();
    }
    handleMouseLeave() {
        this.handleMouseUp();
    }

    render() {
        const { bookData, currentPage } = this.state;
        const { activeBook, scroll, toggleScrollSetting } = this.props;

        return (
            <div
                className="book-reader"

                // Mobile swipe events
                onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
                onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
                onTouchEnd={() => this.handleTouchEnd()}
                // Desktop swipe events
                onMouseDown={mouseDownEvent => this.handleMouseDown(mouseDownEvent)}
                onMouseMove={mouseMoveEvent => this.handleMouseMove(mouseMoveEvent)}
                onMouseUp={() => this.handleMouseUp()}
                onMouseLeave={() => this.handleMouseLeave()}
            >
                {bookData.title &&
                    <Panorama
                        toggleScrollSetting={toggleScrollSetting}
                        scroll={scroll && bookData.pageScroll[currentPage][0]}
                        reverse={scroll && bookData.pageScroll[currentPage][1]}
                        backgroundImage={bookData.pageImage[currentPage] || LoadingIcon}
                        content={""}
                        pageText={bookData.pageText[currentPage] || ""}
                    />
                }
                <div className="home-button" onClick={this.backToHome} />
            </div>
        );
    }
}
 
export default BookReader;