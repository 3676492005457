import React, { Component } from 'react';
import Panorama from './components/panorama/Panorama';
import HomePanorama from './img/SiteBackground.svg';
import BookTileList from './components/bookTileList/BookTileList';
import 'bootstrap/dist/css/bootstrap.min.css';
import BookConfig from './books/config.json';
import BookReader from './components/bookReader/BookReader';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollSetting: true,
      readingBook: false,
      activeBook: null
    };
  }

  toggleScrollSetting = () => {
    this.setState({
      scrollSetting: !this.state.scrollSetting
    })
  };

  toggleReadingBook = (activeBook) => {
    this.setState({
      readingBook: !this.state.readingBook,
      activeBook: activeBook
    })
  }

  render() {
    const { scrollSetting, readingBook, activeBook } = this.state;

    return (
      <div className="App">
        <header className="App-header">
          {!readingBook &&
            <Panorama
              toggleScrollSetting={this.toggleScrollSetting}
              scroll={scrollSetting}
              backgroundImage={HomePanorama}
              content={""}
              pageText={""}
            >
              <BookTileList
                bookConfig={BookConfig}
                toggleReadingBook={this.toggleReadingBook}
              />
            </Panorama>
          }
          {readingBook &&
            <BookReader
              bookConfig={BookConfig}
              activeBook={activeBook}
              toggleScrollSetting={this.toggleScrollSetting}
              scroll={scrollSetting}
              toggleReadingBook={this.toggleReadingBook}
            />
          }
        </header>
      </div>
    );
  }
}

export default App;
