import React, { Component } from 'react';
import './Panorama.css';

class Panorama extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: props.time || "60",
            hidePageText: props.pageText ? false : true,
        };
    }

    togglePageText = () => {
        this.setState({
            hidePageText: !this.state.hidePageText
        });
    }

    render() {
        const { time, hidePageText } = this.state;
        const { backgroundImage, scroll, reverse, content, toggleScrollSetting, pageText, pageTextPosition } = this.props;
        const scrollStateClass = scroll ? "scroll-active" : "scroll-disabled";
        const repeatStateClass = scroll ? "repeat-active" : "repeat-disabled";
        const pageTextPositionClass = "top-right";
        switch (pageTextPosition) {
            case 1:
                pageTextPositionClass = "top-left";
                break;
            case 2:
                pageTextPositionClass = "top-right";
                break;
            case 3:
                pageTextPositionClass = "bottom-left";
                break;
            case 4:
                pageTextPositionClass = "bottom-right";
                break;
            default:
                break;
        }

        return (
            <div className="panorama-container">
                <div 
                    className={`panorama ${repeatStateClass}`}
                    style={{
                        backgroundImage: `url("${backgroundImage}")`,
                        animation: scroll ? `scroll ${time}s linear infinite` : "none",
                        animationDirection: reverse ? "reverse" : "normal"
                    }}
                >
                    <div dangerouslySetInnerHTML={{__html: content}}></div>
                    {this.props.children}
                    {(pageText && !hidePageText) &&
                        <div className={`page-text ${pageTextPositionClass}`} onClick={this.togglePageText}>
                            {pageText}
                        </div>
                    }
                    {(pageText && hidePageText) &&
                        <div className={`toggle-page-text ${pageTextPositionClass}`} onClick={this.togglePageText} />
                    }
                    <div
                        className={`toggle-scroll ${scrollStateClass}`}
                        onClick={() => {toggleScrollSetting()}}
                    />
                    <div className="play-sound" />
                </div>
            </div>
        );
    }
}

export default Panorama;