import React, { Component } from 'react';
import BookTile from "./../bookTile/BookTile";
import { CardDeck } from 'react-bootstrap';
import './BookTileList.css';

class BookTileList extends Component {
    state = {}
    
    render() {
        const { toggleReadingBook, bookConfig } = this.props;

        return (
            <CardDeck className="book-tile-container">
                {bookConfig.books.map((book, i) => {
                    return (
                        <BookTile key={i} title={book.title} description={book.description} systemName={book.systemName} toggleReadingBook={toggleReadingBook}/>
                    ) 
                })}
            </CardDeck>
        );
    }
}
 
export default BookTileList;