import React, { Component } from 'react';
import { Card } from "react-bootstrap";
import './BookTile.css';

class BookTile extends Component {
    state = {}

    componentDidMount() {
        this.loadImage(this.props.systemName);
    }

    loadImage = systemName => {
        import(`./../../books/${systemName}/${systemName}.svg`).then(thumbnail => {
            this.setState({
                thumbnail
            });
        });
    };

    render() {
        const { thumbnail } = this.state;
        const { title, description, systemName, toggleReadingBook } = this.props;

        return (
            <Card className="book-tile" bg="dark" border="light" onClick={() => {toggleReadingBook(systemName)}}>
                {thumbnail && <Card.Img variant="top" src={thumbnail.default} />}
                <Card.Body>
                    <Card.Title className="font-weight-bold">{title}</Card.Title>
                    <Card.Text>
                        {description}
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}
 
export default BookTile;